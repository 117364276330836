import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../services/api';
import { TextField, Button, Container, Typography, IconButton, Paper } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';

const ArticleForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [media, setMedia] = useState(null);

  useEffect(() => {
    if (id) {
      const fetchArticle = async () => {
        try {
          const response = await api.get(`/articles/${id}`);
          const { title, content, media } = response.data;
          setTitle(title);
          setContent(content);
          setMedia(media);
        } catch (error) {
          console.error('Fetch article error:', error);
        }
      };
      fetchArticle();
    }
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', content);
    if (media) formData.append('media', media);

    try {
      if (id) {
        await api.put(`/articles/${id}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
      } else {
        await api.post('/articles', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
      }
      navigate('/articles');
    } catch (error) {
      console.error('Save article error:', error);
    }
  };

  return (
    <Container maxWidth="sm">
      <IconButton onClick={() => navigate('/articles')}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h4" gutterBottom>{id ? 'Edit Article' : 'Create Article'}</Typography>
      <Paper style={{ padding: '20px' }}>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Title"
            fullWidth
            margin="normal"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <TextField
            label="Content"
            fullWidth
            margin="normal"
            multiline
            rows={4}
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
          />
          <input
            type="file"
            onChange={(e) => setMedia(e.target.files[0])}
            style={{ marginTop: '10px', marginBottom: '10px' }}
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            {id ? 'Update' : 'Create'}
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default ArticleForm;
