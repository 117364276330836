import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
import { Button, Container, Typography, List, ListItem, ListItemText, ListItemAvatar, Avatar, IconButton, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const ProductList = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await api.get('/products');
        setProducts(response.data);
      } catch (error) {
        console.error('Fetch products error:', error);
      }
    };
    fetchProducts();
  }, []);

  const handleDelete = async (id) => {
    try {
      await api.delete(`/products/${id}`);
      setProducts(products.filter(product => product.id !== id));
    } catch (error) {
      console.error('Delete product error:', error);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Products</Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => navigate('/products/new')}
        style={{ marginBottom: '20px' }}
      >
        Create New Product
      </Button>
      <List>
        {products.map((product) => (
          <Paper key={product.id} style={{ marginBottom: '10px' }}>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  variant="square"
                  src={`https://api.nexaitech.com/${product.media}`}
                  alt={product.name}
                  style={{ width: 100, height: 100 }}
                />
              </ListItemAvatar>
              <ListItemText
                primary={product.name}
                secondary={product.description}
                onClick={() => navigate(`/products/edit/${product.id}`)}
              />
              <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(product.id)}>
                <DeleteIcon />
              </IconButton>
            </ListItem>
          </Paper>
        ))}
      </List>
    </Container>
  );
};

export default ProductList;
