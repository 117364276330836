import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
import { Button, Container, Typography, List, ListItem, ListItemText, ListItemAvatar, Avatar, IconButton, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const ProjectList = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await api.get('/projects');
        setProjects(response.data);
      } catch (error) {
        console.error('Fetch projects error:', error);
      }
    };
    fetchProjects();
  }, []);

  const handleDelete = async (id) => {
    try {
      await api.delete(`/projects/${id}`);
      setProjects(projects.filter(project => project.id !== id));
    } catch (error) {
      console.error('Delete project error:', error);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Projects</Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => navigate('/projects/new')}
        style={{ marginBottom: '20px' }}
      >
        Create New Project
      </Button>
      <List>
        {projects.map((project) => (
          <Paper key={project.id} style={{ marginBottom: '10px' }}>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  variant="square"
                  src={`https://api.nexaitech.com/${project.media}`}
                  alt={project.name}
                  style={{ width: 100, height: 100 }}
                />
              </ListItemAvatar>
              <ListItemText
                primary={project.name}
                secondary={project.description}
                onClick={() => navigate(`/projects/edit/${project.id}`)}
              />
              <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(project.id)}>
                <DeleteIcon />
              </IconButton>
            </ListItem>
          </Paper>
        ))}
      </List>
    </Container>
  );
};

export default ProjectList;
