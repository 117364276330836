import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import Dashboard from './components/Dashboard/Dashboard';
import ArticleList from './components/Articles/ArticleList';
import ArticleForm from './components/Articles/ArticleForm';
import ProjectList from './components/Projects/ProjectList';
import ProjectForm from './components/Projects/ProjectForm';
import ProductList from './components/Products/ProductList';
import ProductForm from './components/Products/ProductForm';
import PrivateRoute from './components/PrivateRoute';
import Layout from './components/Layout/Layout';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/" element={<PrivateRoute><Layout><Dashboard /></Layout></PrivateRoute>} />
        <Route path="/dashboard" element={<PrivateRoute><Layout><Dashboard /></Layout></PrivateRoute>} />
        <Route path="/articles" element={<PrivateRoute><Layout><ArticleList /></Layout></PrivateRoute>} />
        <Route path="/articles/new" element={<PrivateRoute><Layout><ArticleForm /></Layout></PrivateRoute>} />
        <Route path="/articles/edit/:id" element={<PrivateRoute><Layout><ArticleForm /></Layout></PrivateRoute>} />
        <Route path="/projects" element={<PrivateRoute><Layout><ProjectList /></Layout></PrivateRoute>} />
        <Route path="/projects/new" element={<PrivateRoute><Layout><ProjectForm /></Layout></PrivateRoute>} />
        <Route path="/projects/edit/:id" element={<PrivateRoute><Layout><ProjectForm /></Layout></PrivateRoute>} />
        <Route path="/products" element={<PrivateRoute><Layout><ProductList /></Layout></PrivateRoute>} />
        <Route path="/products/new" element={<PrivateRoute><Layout><ProductForm /></Layout></PrivateRoute>} />
        <Route path="/products/edit/:id" element={<PrivateRoute><Layout><ProductForm /></Layout></PrivateRoute>} />
      </Routes>
    </Router>
  );
}

export default App;
