import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
import { Button, Container, Typography, List, ListItem, ListItemText, ListItemAvatar, Avatar, IconButton, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const ArticleList = () => {
  const navigate = useNavigate();
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await api.get('/articles');
        setArticles(response.data);
      } catch (error) {
        console.error('Fetch articles error:', error);
      }
    };
    fetchArticles();
  }, []);

  const handleDelete = async (id) => {
    try {
      await api.delete(`/articles/${id}`);
      setArticles(articles.filter(article => article.id !== id));
    } catch (error) {
      console.error('Delete article error:', error);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Articles</Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => navigate('/articles/new')}
        style={{ marginBottom: '20px' }}
      >
        Create New Article
      </Button>
      <List>
        {articles.map((article) => (
          <Paper key={article.id} style={{ marginBottom: '10px' }}>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  variant="square"
                  src={`https://api.nexaitech.com/${article.media}`}
                  alt={article.title}
                  style={{ width: 100, height: 100 }}
                />
              </ListItemAvatar>
              <ListItemText
                primary={article.title}
                secondary={article.content}
                onClick={() => navigate(`/articles/edit/${article.id}`)}
              />
              <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(article.id)}>
                <DeleteIcon />
              </IconButton>
            </ListItem>
          </Paper>
        ))}
      </List>
    </Container>
  );
};

export default ArticleList;
