import React from 'react';
import { Container, Typography, IconButton } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';
import WebIcon from '@mui/icons-material/Web';

const Footer = () => {
  return (
    <Container style={{ textAlign: 'center', marginTop: '20px' }}>
      <Typography variant="body1">© 2024 NexAI Tech</Typography>
      <IconButton href="https://www.linkedin.com/company/nexaitech" target="_blank">
        <LinkedInIcon />
      </IconButton>
      <IconButton href="https://twitter.com/nexaitech7" target="_blank">
        <TwitterIcon />
      </IconButton>
      <IconButton href="https://github.com/nexaitech" target="_blank">
        <GitHubIcon />
      </IconButton>
      <IconButton href="https://nexaitech.com" target="_blank">
        <WebIcon />
      </IconButton>
    </Container>
  );
};

export default Footer;
