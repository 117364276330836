import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../services/api';
import { TextField, Button, Container, Typography, IconButton, Paper } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';

const ProjectForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [media, setMedia] = useState(null);

  useEffect(() => {
    if (id) {
      const fetchProject = async () => {
        try {
          const response = await api.get(`/projects/${id}`);
          const { name, description, media } = response.data;
          setName(name);
          setDescription(description);
          setMedia(media);
        } catch (error) {
          console.error('Fetch project error:', error);
        }
      };
      fetchProject();
    }
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    if (media) formData.append('media', media);

    try {
      if (id) {
        await api.put(`/projects/${id}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
      } else {
        await api.post('/projects', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
      }
      navigate('/projects');
    } catch (error) {
      console.error('Save project error:', error);
    }
  };

  return (
    <Container maxWidth="sm">
      <IconButton onClick={() => navigate('/projects')}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h4" gutterBottom>{id ? 'Edit Project' : 'Create Project'}</Typography>
      <Paper style={{ padding: '20px' }}>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Name"
            fullWidth
            margin="normal"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <TextField
            label="Description"
            fullWidth
            margin="normal"
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
          <input
            type="file"
            onChange={(e) => setMedia(e.target.files[0])}
            style={{ marginTop: '10px', marginBottom: '10px' }}
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            {id ? 'Update' : 'Create'}
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default ProjectForm;
